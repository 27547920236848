import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { UserProvider } from "./contexts";
import { RouterProvider } from "react-router-dom";
import { router } from "./routing/router";
import { SelectedProductProvider } from "./contexts/selected-product-provider";
import { TranslationsProvider } from "./contexts/translations-context";
import { ThemeProvider, createTheme } from "@mui/material";
import { CenterComponent } from "./components/layout/center-component";
import Clarity from '@microsoft/clarity';
import { isProdEnvironment } from "./helpers/env-helpers";

const theme = createTheme({
  typography: {
    fontFamily: [
      "SwegonBrown",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      light: "#53C427",
      main: "#39AA0D",
      dark: "#277D32",
      contrastText: "#fff",
    },
    secondary: {
      light: "#18B8D7",
      main: "#00857F",
      dark: "#C14B0F",
      contrastText: "#000",
    },
  },
});

if (isProdEnvironment()) {
  const clarityProjectId = "qk1mb3y3yh";
  Clarity.init(clarityProjectId);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <UserProvider>
        <TranslationsProvider>
          <SelectedProductProvider>
            <CenterComponent>
              <RouterProvider router={router} />
            </CenterComponent>
          </SelectedProductProvider>
        </TranslationsProvider>
      </UserProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
